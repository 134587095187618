"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_SET_CONTEXT_OPTION = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var set_context_option_request_interface_1 = require("./set-context-option-request.interface");
Object.defineProperty(exports, "REQUEST_SET_CONTEXT_OPTION", {
  enumerable: true,
  get: function () {
    return set_context_option_request_interface_1.REQUEST_SET_CONTEXT_OPTION;
  }
});
