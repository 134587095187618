"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_STOP_CINEMATIC = exports.REQUEST_PLAY_CINEMATIC = exports.REQUEST_GET_CINEMATICS = void 0;
var get_cinematics_request_interface_1 = require("./get-cinematics-request.interface");
Object.defineProperty(exports, "REQUEST_GET_CINEMATICS", {
  enumerable: true,
  get: function () {
    return get_cinematics_request_interface_1.REQUEST_GET_CINEMATICS;
  }
});
var play_cinematic_request_interface_1 = require("./play-cinematic-request.interface");
Object.defineProperty(exports, "REQUEST_PLAY_CINEMATIC", {
  enumerable: true,
  get: function () {
    return play_cinematic_request_interface_1.REQUEST_PLAY_CINEMATIC;
  }
});
var stop_cinematic_request_interface_1 = require("./stop-cinematic-request.interface");
Object.defineProperty(exports, "REQUEST_STOP_CINEMATIC", {
  enumerable: true,
  get: function () {
    return stop_cinematic_request_interface_1.REQUEST_STOP_CINEMATIC;
  }
});
