"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_VIDEO_CREATED = exports.EVENT_MOODBOARD_CREATED = exports.REQUEST_GENERATE_MOODBOARD = exports.REQUEST_GENERATE_ASSETS = exports.REQUEST_EXORT_VIDEO = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var export_video_request_interface_1 = require("./export-video-request.interface");
Object.defineProperty(exports, "REQUEST_EXORT_VIDEO", {
  enumerable: true,
  get: function () {
    return export_video_request_interface_1.REQUEST_EXORT_VIDEO;
  }
});
var generate_assets_request_interface_1 = require("./generate-assets-request.interface");
Object.defineProperty(exports, "REQUEST_GENERATE_ASSETS", {
  enumerable: true,
  get: function () {
    return generate_assets_request_interface_1.REQUEST_GENERATE_ASSETS;
  }
});
var generate_moodboard_request_interface_1 = require("./generate-moodboard-request.interface");
Object.defineProperty(exports, "REQUEST_GENERATE_MOODBOARD", {
  enumerable: true,
  get: function () {
    return generate_moodboard_request_interface_1.REQUEST_GENERATE_MOODBOARD;
  }
});
var moodboard_created_event_interface_1 = require("./moodboard-created-event.interface");
Object.defineProperty(exports, "EVENT_MOODBOARD_CREATED", {
  enumerable: true,
  get: function () {
    return moodboard_created_event_interface_1.EVENT_MOODBOARD_CREATED;
  }
});
var video_created_event_interface_1 = require("./video-created-event.interface");
Object.defineProperty(exports, "EVENT_VIDEO_CREATED", {
  enumerable: true,
  get: function () {
    return video_created_event_interface_1.EVENT_VIDEO_CREATED;
  }
});
