"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_HEARTBEAT = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var heartbeat_request_interface_1 = require("./heartbeat-request.interface");
Object.defineProperty(exports, "REQUEST_HEARTBEAT", {
  enumerable: true,
  get: function () {
    return heartbeat_request_interface_1.REQUEST_HEARTBEAT;
  }
});
