"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StreamConnectionTimeoutError = exports.MissingParameterError = exports.SignalingConnectionCutError = exports.SignalingConnectionError = exports.NoRendererAvailableError = exports.StreamConnectionCutError = exports.StreamConnectionError = exports.StreamError = exports.EndpointResponseError = exports.EndpointUnavailableError = exports.RequestTimeoutError = exports.RequestError = exports.InvalidAnimationsError = exports.ConnectionUnavailableError = exports.InvalidEnvironmentError = exports.InvalidCameraError = exports.InvalidProductError = exports.NotAuthorizedError = exports.NotAuthenticatedError = exports.NotImplementedError = exports.UserCancelledError = exports.NotFoundError = exports.IllegalStateError = exports.CustomError = void 0;
class CustomError extends Error {
  /**
   * Tests wether a given errorCandidate is of type CustomerError.
   * @param errorCadidate
   */
  static isCustomError(errorCadidate) {
    return errorCadidate instanceof CustomError;
  }
  constructor(message, details) {
    super(message);
    this.message = message;
    this.details = details;
    this.name = new.target.name;
    // see https://github.com/Microsoft/TypeScript/issues/13965 for this mess...
    const trueProto = new.target.prototype;
    // eslint-disable-next-line no-proto
    this.__proto__ = trueProto;
  }
}
exports.CustomError = CustomError;
/**
 * Error thrown in case an expectation could not be fulfilled.
 */
class IllegalStateError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.IllegalStateError = IllegalStateError;
/**
 * Error thrown in case something could not be found.
 */
class NotFoundError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.NotFoundError = NotFoundError;
/**
 * Error thrown in case a user cancelled a process.
 */
class UserCancelledError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.UserCancelledError = UserCancelledError;
/**
 * Error thrown in case an implementation is not there yet.
 */
class NotImplementedError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message || 'Not implemented yet', details);
  }
}
exports.NotImplementedError = NotImplementedError;
/**
 * Error thrown in case a user is not authenticated.
 */
class NotAuthenticatedError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.NotAuthenticatedError = NotAuthenticatedError;
/**
 * Error thrown in case a user is not authorized to perform a certain action.
 */
class NotAuthorizedError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.NotAuthorizedError = NotAuthorizedError;
class InvalidProductError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.InvalidProductError = InvalidProductError;
/**
 * Error thrown in case a camera is not valid, e.g. does not exist.
 */
class InvalidCameraError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.InvalidCameraError = InvalidCameraError;
/**
 * Error thrown in case an environment is not valid, e.g. does not exist.
 */
class InvalidEnvironmentError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.InvalidEnvironmentError = InvalidEnvironmentError;
/**
 * Error thrown in case an error occurs during connection.
 */
class ConnectionUnavailableError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.ConnectionUnavailableError = ConnectionUnavailableError;
/**
 * Error thrown in case at least one animation is not valid, e.g. does not exist
 */
class InvalidAnimationsError extends CustomError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message, details) {
    super(message, details);
  }
}
exports.InvalidAnimationsError = InvalidAnimationsError;
class RequestError extends CustomError {
  constructor(message, source) {
    super(message, source);
  }
}
exports.RequestError = RequestError;
/**
 * Error thrown in case a timeout happens while waiting for a response.
 */
class RequestTimeoutError extends RequestError {
  constructor(message, source) {
    super(message, source);
  }
}
exports.RequestTimeoutError = RequestTimeoutError;
/**
 * Error thrown in case an rpc-endpoint is not available.
 */
class EndpointUnavailableError extends RequestError {
  constructor(message, source) {
    super(message, source);
  }
}
exports.EndpointUnavailableError = EndpointUnavailableError;
/**
 * Error thrown in case an error is thrown by the endpoint.
 */
class EndpointResponseError extends RequestError {
  constructor(message, source) {
    super(message, source);
  }
}
exports.EndpointResponseError = EndpointResponseError;
/**
 * Error thrown in case an error occurs while working with a stream.
 */
class StreamError extends CustomError {
  constructor(message, details) {
    super(message, details);
  }
}
exports.StreamError = StreamError;
class StreamConnectionError extends StreamError {
  constructor(message, details) {
    super(message, details);
  }
}
exports.StreamConnectionError = StreamConnectionError;
class StreamConnectionCutError extends StreamError {
  constructor(message, cutType, details) {
    super(message, details);
    Object.setPrototypeOf(this, StreamConnectionCutError.prototype);
    this.cutType = cutType;
  }
}
exports.StreamConnectionCutError = StreamConnectionCutError;
class NoRendererAvailableError extends StreamError {
  constructor(message, details) {
    super(message, details);
    Object.setPrototypeOf(this, NoRendererAvailableError.prototype);
  }
}
exports.NoRendererAvailableError = NoRendererAvailableError;
class SignalingConnectionError extends CustomError {
  constructor(message) {
    super(message);
    Object.setPrototypeOf(this, SignalingConnectionError.prototype);
  }
}
exports.SignalingConnectionError = SignalingConnectionError;
class SignalingConnectionCutError extends CustomError {
  constructor(message, cutType) {
    super(message);
    Object.setPrototypeOf(this, SignalingConnectionCutError.prototype);
    this.cutType = cutType;
  }
}
exports.SignalingConnectionCutError = SignalingConnectionCutError;
class MissingParameterError extends CustomError {
  constructor(message, ...params) {
    super(message);
    this.errorParamsInContext = [];
    Object.setPrototypeOf(this, MissingParameterError.prototype);
    this.errorParamsInContext = params;
  }
}
exports.MissingParameterError = MissingParameterError;
class StreamConnectionTimeoutError extends StreamError {
  constructor(message, details) {
    super(message, details);
    Object.setPrototypeOf(this, StreamConnectionTimeoutError.prototype);
  }
}
exports.StreamConnectionTimeoutError = StreamConnectionTimeoutError;
