"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_STOP_CAPTURE_CINEMATIC = exports.EVENT_CAPTURED_CINEMATIC = exports.REQUEST_CAPTURE_CINEMATIC = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var capture_cinematic_request_interface_1 = require("./capture-cinematic-request.interface");
Object.defineProperty(exports, "REQUEST_CAPTURE_CINEMATIC", {
  enumerable: true,
  get: function () {
    return capture_cinematic_request_interface_1.REQUEST_CAPTURE_CINEMATIC;
  }
});
var captured_cinematic_event_interface_1 = require("./captured-cinematic-event.interface");
Object.defineProperty(exports, "EVENT_CAPTURED_CINEMATIC", {
  enumerable: true,
  get: function () {
    return captured_cinematic_event_interface_1.EVENT_CAPTURED_CINEMATIC;
  }
});
var stop_capture_cinematic_request_interface_1 = require("./stop-capture-cinematic-request.interface");
Object.defineProperty(exports, "REQUEST_STOP_CAPTURE_CINEMATIC", {
  enumerable: true,
  get: function () {
    return stop_capture_cinematic_request_interface_1.REQUEST_STOP_CAPTURE_CINEMATIC;
  }
});
