"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserCancelledError = exports.StreamError = exports.StreamConnectionTimeoutError = exports.StreamConnectionError = exports.StreamConnectionCutError = exports.SignalingConnectionError = exports.SignalingConnectionCutError = exports.RequestTimeoutError = exports.RequestError = exports.NotImplementedError = exports.NotFoundError = exports.NotAuthorizedError = exports.NotAuthenticatedError = exports.NoRendererAvailableError = exports.MissingParameterError = exports.InvalidProductError = exports.InvalidEnvironmentError = exports.InvalidCameraError = exports.InvalidAnimationsError = exports.IllegalStateError = exports.EndpointUnavailableError = exports.EndpointResponseError = exports.CustomError = exports.ConnectionUnavailableError = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var error_1 = require("./error");
Object.defineProperty(exports, "ConnectionUnavailableError", {
  enumerable: true,
  get: function () {
    return error_1.ConnectionUnavailableError;
  }
});
Object.defineProperty(exports, "CustomError", {
  enumerable: true,
  get: function () {
    return error_1.CustomError;
  }
});
Object.defineProperty(exports, "EndpointResponseError", {
  enumerable: true,
  get: function () {
    return error_1.EndpointResponseError;
  }
});
Object.defineProperty(exports, "EndpointUnavailableError", {
  enumerable: true,
  get: function () {
    return error_1.EndpointUnavailableError;
  }
});
Object.defineProperty(exports, "IllegalStateError", {
  enumerable: true,
  get: function () {
    return error_1.IllegalStateError;
  }
});
Object.defineProperty(exports, "InvalidAnimationsError", {
  enumerable: true,
  get: function () {
    return error_1.InvalidAnimationsError;
  }
});
Object.defineProperty(exports, "InvalidCameraError", {
  enumerable: true,
  get: function () {
    return error_1.InvalidCameraError;
  }
});
Object.defineProperty(exports, "InvalidEnvironmentError", {
  enumerable: true,
  get: function () {
    return error_1.InvalidEnvironmentError;
  }
});
Object.defineProperty(exports, "InvalidProductError", {
  enumerable: true,
  get: function () {
    return error_1.InvalidProductError;
  }
});
Object.defineProperty(exports, "MissingParameterError", {
  enumerable: true,
  get: function () {
    return error_1.MissingParameterError;
  }
});
Object.defineProperty(exports, "NoRendererAvailableError", {
  enumerable: true,
  get: function () {
    return error_1.NoRendererAvailableError;
  }
});
Object.defineProperty(exports, "NotAuthenticatedError", {
  enumerable: true,
  get: function () {
    return error_1.NotAuthenticatedError;
  }
});
Object.defineProperty(exports, "NotAuthorizedError", {
  enumerable: true,
  get: function () {
    return error_1.NotAuthorizedError;
  }
});
Object.defineProperty(exports, "NotFoundError", {
  enumerable: true,
  get: function () {
    return error_1.NotFoundError;
  }
});
Object.defineProperty(exports, "NotImplementedError", {
  enumerable: true,
  get: function () {
    return error_1.NotImplementedError;
  }
});
Object.defineProperty(exports, "RequestError", {
  enumerable: true,
  get: function () {
    return error_1.RequestError;
  }
});
Object.defineProperty(exports, "RequestTimeoutError", {
  enumerable: true,
  get: function () {
    return error_1.RequestTimeoutError;
  }
});
Object.defineProperty(exports, "SignalingConnectionCutError", {
  enumerable: true,
  get: function () {
    return error_1.SignalingConnectionCutError;
  }
});
Object.defineProperty(exports, "SignalingConnectionError", {
  enumerable: true,
  get: function () {
    return error_1.SignalingConnectionError;
  }
});
Object.defineProperty(exports, "StreamConnectionCutError", {
  enumerable: true,
  get: function () {
    return error_1.StreamConnectionCutError;
  }
});
Object.defineProperty(exports, "StreamConnectionError", {
  enumerable: true,
  get: function () {
    return error_1.StreamConnectionError;
  }
});
Object.defineProperty(exports, "StreamConnectionTimeoutError", {
  enumerable: true,
  get: function () {
    return error_1.StreamConnectionTimeoutError;
  }
});
Object.defineProperty(exports, "StreamError", {
  enumerable: true,
  get: function () {
    return error_1.StreamError;
  }
});
Object.defineProperty(exports, "UserCancelledError", {
  enumerable: true,
  get: function () {
    return error_1.UserCancelledError;
  }
});
