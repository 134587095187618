"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SignalingHTTPCodes = exports.ReservationHTTPCodes = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var matchmaker_http_codes_enum_1 = require("./matchmaker-http-codes.enum");
Object.defineProperty(exports, "ReservationHTTPCodes", {
  enumerable: true,
  get: function () {
    return matchmaker_http_codes_enum_1.ReservationHTTPCodes;
  }
});
Object.defineProperty(exports, "SignalingHTTPCodes", {
  enumerable: true,
  get: function () {
    return matchmaker_http_codes_enum_1.SignalingHTTPCodes;
  }
});
