"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LatencyLimitData = exports.NoFrameLimitData = exports.BitrateLimitData = void 0;
class LimitDataBase {}
class BitrateLimitData extends LimitDataBase {}
exports.BitrateLimitData = BitrateLimitData;
class NoFrameLimitData extends LimitDataBase {}
exports.NoFrameLimitData = NoFrameLimitData;
class LatencyLimitData extends LimitDataBase {}
exports.LatencyLimitData = LatencyLimitData;
