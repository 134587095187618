"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_STOP_HIGHLIGHT = exports.REQUEST_PLAY_HIGHLIGHT = exports.REQUEST_GET_HIGHLIGHTS = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var get_highlights_request_interface_1 = require("./get-highlights-request.interface");
Object.defineProperty(exports, "REQUEST_GET_HIGHLIGHTS", {
  enumerable: true,
  get: function () {
    return get_highlights_request_interface_1.REQUEST_GET_HIGHLIGHTS;
  }
});
var play_highlight_request_interface_1 = require("./play-highlight-request.interface");
Object.defineProperty(exports, "REQUEST_PLAY_HIGHLIGHT", {
  enumerable: true,
  get: function () {
    return play_highlight_request_interface_1.REQUEST_PLAY_HIGHLIGHT;
  }
});
var stop_highlight_request_interface_1 = require("./stop-highlight-request.interface");
Object.defineProperty(exports, "REQUEST_STOP_HIGHLIGHT", {
  enumerable: true,
  get: function () {
    return stop_highlight_request_interface_1.REQUEST_STOP_HIGHLIGHT;
  }
});
