"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_SET_WATERMARK = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var set_watermark_request_interface_1 = require("./set-watermark-request.interface");
Object.defineProperty(exports, "REQUEST_SET_WATERMARK", {
  enumerable: true,
  get: function () {
    return set_watermark_request_interface_1.REQUEST_SET_WATERMARK;
  }
});
