"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_LOGOUT = exports.REQUEST_LOGIN = exports.REQUEST_LICENSE = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var licence_request_interface_1 = require("./licence-request.interface");
Object.defineProperty(exports, "REQUEST_LICENSE", {
  enumerable: true,
  get: function () {
    return licence_request_interface_1.REQUEST_LICENSE;
  }
});
var login_request_interface_1 = require("./login-request.interface");
Object.defineProperty(exports, "REQUEST_LOGIN", {
  enumerable: true,
  get: function () {
    return login_request_interface_1.REQUEST_LOGIN;
  }
});
var logout_request_interface_1 = require("./logout-request.interface");
Object.defineProperty(exports, "REQUEST_LOGOUT", {
  enumerable: true,
  get: function () {
    return logout_request_interface_1.REQUEST_LOGOUT;
  }
});
