"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_RESTART_ALL = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var restart_request_interface_1 = require("./restart-request.interface");
Object.defineProperty(exports, "REQUEST_RESTART_ALL", {
  enumerable: true,
  get: function () {
    return restart_request_interface_1.REQUEST_RESTART_ALL;
  }
});
