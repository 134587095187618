"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_PATCH_UI_STATE = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var patch_ui_state_request_interface_1 = require("./patch-ui-state-request.interface");
Object.defineProperty(exports, "REQUEST_PATCH_UI_STATE", {
  enumerable: true,
  get: function () {
    return patch_ui_state_request_interface_1.REQUEST_PATCH_UI_STATE;
  }
});
