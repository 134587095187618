"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.instanceOfConfigurationPayload = void 0;
function instanceOfConfigurationPayload(object) {
  return Object.prototype.hasOwnProperty.call(object, 'environment') && Object.prototype.hasOwnProperty.call(object, 'camera') && Object.prototype.hasOwnProperty.call(object, 'animations') && Object.prototype.hasOwnProperty.call(object, 'config');
}
exports.instanceOfConfigurationPayload = instanceOfConfigurationPayload;
