"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_MATERIAL_LIBRARY_UPDATE = exports.PARENT_MATERIALS_FACTORY = exports.MATERIAL_ATTRIBUTE_EXPOSE_TINT = exports.MATERIAL_ATTRIBUTE_EXPOSE_SCUFF_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_ROUGHNESS = exports.MATERIAL_ATTRIBUTE_EXPOSE_PAINT_OPACITY = exports.MATERIAL_ATTRIBUTE_EXPOSE_ORANGE_PEEL_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_MAP = exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_METALLIC = exports.MATERIAL_ATTRIBUTE_EXPOSE_LEATHER_SCALE = exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_SCALE = exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_CC_ROUGHNESS = exports.REQUEST_GET_TEXTURES = exports.REQUEST_GET_MATERIAL_LIBRARIES = exports.REQUEST_DELETE_MATERIALS = exports.REQUEST_DELETE_MATERIAL = exports.REQUEST_DELETE_MATERIAL_LIBRARY = exports.EVENT_MATERIAL_LIBRARY_DELETE = exports.REQUEST_CLONE_MATERIAL_LIBRARY = exports.REQUEST_ADD_MATERIALS = exports.REQUEST_ADD_MATERIAL = exports.REQUEST_ADD_MATERIAL_LIBRARY = exports.EVENT_MATERIAL_LIBRARY_ADD = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var add_event_interface_1 = require("./add-event.interface");
Object.defineProperty(exports, "EVENT_MATERIAL_LIBRARY_ADD", {
  enumerable: true,
  get: function () {
    return add_event_interface_1.EVENT_MATERIAL_LIBRARY_ADD;
  }
});
var add_material_library_request_interface_1 = require("./add-material-library-request.interface");
Object.defineProperty(exports, "REQUEST_ADD_MATERIAL_LIBRARY", {
  enumerable: true,
  get: function () {
    return add_material_library_request_interface_1.REQUEST_ADD_MATERIAL_LIBRARY;
  }
});
var add_material_request_interface_1 = require("./add-material-request.interface");
Object.defineProperty(exports, "REQUEST_ADD_MATERIAL", {
  enumerable: true,
  get: function () {
    return add_material_request_interface_1.REQUEST_ADD_MATERIAL;
  }
});
var add_materials_request_interface_1 = require("./add-materials-request.interface");
Object.defineProperty(exports, "REQUEST_ADD_MATERIALS", {
  enumerable: true,
  get: function () {
    return add_materials_request_interface_1.REQUEST_ADD_MATERIALS;
  }
});
var clone_material_library_request_interface_1 = require("./clone-material-library-request.interface");
Object.defineProperty(exports, "REQUEST_CLONE_MATERIAL_LIBRARY", {
  enumerable: true,
  get: function () {
    return clone_material_library_request_interface_1.REQUEST_CLONE_MATERIAL_LIBRARY;
  }
});
var delete_event_interface_1 = require("./delete-event.interface");
Object.defineProperty(exports, "EVENT_MATERIAL_LIBRARY_DELETE", {
  enumerable: true,
  get: function () {
    return delete_event_interface_1.EVENT_MATERIAL_LIBRARY_DELETE;
  }
});
var delete_material_library_request_interface_1 = require("./delete-material-library-request.interface");
Object.defineProperty(exports, "REQUEST_DELETE_MATERIAL_LIBRARY", {
  enumerable: true,
  get: function () {
    return delete_material_library_request_interface_1.REQUEST_DELETE_MATERIAL_LIBRARY;
  }
});
var delete_material_request_interface_1 = require("./delete-material-request.interface");
Object.defineProperty(exports, "REQUEST_DELETE_MATERIAL", {
  enumerable: true,
  get: function () {
    return delete_material_request_interface_1.REQUEST_DELETE_MATERIAL;
  }
});
var delete_materials_request_interface_1 = require("./delete-materials-request.interface");
Object.defineProperty(exports, "REQUEST_DELETE_MATERIALS", {
  enumerable: true,
  get: function () {
    return delete_materials_request_interface_1.REQUEST_DELETE_MATERIALS;
  }
});
var get_material_libraries_request_interface_1 = require("./get-material-libraries-request.interface");
Object.defineProperty(exports, "REQUEST_GET_MATERIAL_LIBRARIES", {
  enumerable: true,
  get: function () {
    return get_material_libraries_request_interface_1.REQUEST_GET_MATERIAL_LIBRARIES;
  }
});
var get_textures_request_interface_1 = require("./get-textures-request.interface");
Object.defineProperty(exports, "REQUEST_GET_TEXTURES", {
  enumerable: true,
  get: function () {
    return get_textures_request_interface_1.REQUEST_GET_TEXTURES;
  }
});
var parent_material_definitions_1 = require("./parent-material-definitions");
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_CC_ROUGHNESS", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_CC_ROUGHNESS;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_AMOUNT", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_AMOUNT;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_SCALE", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_SCALE;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_LEATHER_SCALE", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_LEATHER_SCALE;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_METALLIC", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_METALLIC;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_AMOUNT", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_AMOUNT;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_MAP", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_MAP;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_ORANGE_PEEL_AMOUNT", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_ORANGE_PEEL_AMOUNT;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_PAINT_OPACITY", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_PAINT_OPACITY;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_ROUGHNESS", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_ROUGHNESS;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_SCUFF_AMOUNT", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_SCUFF_AMOUNT;
  }
});
Object.defineProperty(exports, "MATERIAL_ATTRIBUTE_EXPOSE_TINT", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.MATERIAL_ATTRIBUTE_EXPOSE_TINT;
  }
});
Object.defineProperty(exports, "PARENT_MATERIALS_FACTORY", {
  enumerable: true,
  get: function () {
    return parent_material_definitions_1.PARENT_MATERIALS_FACTORY;
  }
});
var update_event_interface_1 = require("./update-event.interface");
Object.defineProperty(exports, "EVENT_MATERIAL_LIBRARY_UPDATE", {
  enumerable: true,
  get: function () {
    return update_event_interface_1.EVENT_MATERIAL_LIBRARY_UPDATE;
  }
});
