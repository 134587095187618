"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_GET_CONFIGURATION_CODES = exports.REQUEST_GET_CONFIGURATION_CODE = exports.REQUEST_GET_CONFIGURATION_CODE_DETAILS = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var configuration_code_details_request_interface_1 = require("./configuration-code-details-request.interface");
Object.defineProperty(exports, "REQUEST_GET_CONFIGURATION_CODE_DETAILS", {
  enumerable: true,
  get: function () {
    return configuration_code_details_request_interface_1.REQUEST_GET_CONFIGURATION_CODE_DETAILS;
  }
});
var configuration_code_request_interface_1 = require("./configuration-code-request.interface");
Object.defineProperty(exports, "REQUEST_GET_CONFIGURATION_CODE", {
  enumerable: true,
  get: function () {
    return configuration_code_request_interface_1.REQUEST_GET_CONFIGURATION_CODE;
  }
});
var configuration_codes_request_interface_1 = require("./configuration-codes-request.interface");
Object.defineProperty(exports, "REQUEST_GET_CONFIGURATION_CODES", {
  enumerable: true,
  get: function () {
    return configuration_codes_request_interface_1.REQUEST_GET_CONFIGURATION_CODES;
  }
});
