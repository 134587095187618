"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StreamingWebsocketCode = void 0;
const streaming_websocket_message_class_1 = require("./streaming-websocket-message.class");
class StreamingWebsocketCode extends streaming_websocket_message_class_1.StreamingWebsocketMessage {
  constructor(type, code) {
    super(type);
    this.code = code;
  }
}
exports.StreamingWebsocketCode = StreamingWebsocketCode;
