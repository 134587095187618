"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_UPDATE_MATERIAL = exports.EVENT_MOVE_MATERIAL_PREVIEW = exports.REQUEST_MATERIAL_EDIT_MODE = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var material_edit_mode_request_interface_1 = require("./material-edit-mode-request.interface");
Object.defineProperty(exports, "REQUEST_MATERIAL_EDIT_MODE", {
  enumerable: true,
  get: function () {
    return material_edit_mode_request_interface_1.REQUEST_MATERIAL_EDIT_MODE;
  }
});
var move_material_preview_event_interface_1 = require("./move-material-preview-event.interface");
Object.defineProperty(exports, "EVENT_MOVE_MATERIAL_PREVIEW", {
  enumerable: true,
  get: function () {
    return move_material_preview_event_interface_1.EVENT_MOVE_MATERIAL_PREVIEW;
  }
});
var update_material_event_interface_1 = require("./update-material-event.interface");
Object.defineProperty(exports, "EVENT_UPDATE_MATERIAL", {
  enumerable: true,
  get: function () {
    return update_material_event_interface_1.EVENT_UPDATE_MATERIAL;
  }
});
