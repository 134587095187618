import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src';
import { AMLCameraMeta } from '@mhp/aml-shared/product-data/aml-camera-meta.interface';
import { EngineSessionData } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, distinctUntilChangedEquality } from '@mhp/common';
import {
    ImageSrcset,
    UiHotspot,
    UiHotspotEvent,
    UiHotspotEventType
} from '@mhp/ui-components';
import {
    ProductConfigurationService,
    ProductDataService
} from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';

import { environment } from '../../../../environments/environment';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { CameraControlService } from '../../camera-control/camera-control.service';
import { ExtendedUiOptionCode } from '../../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';
import { StaticRendererService } from '../../static-renderer/static-renderer.service';

interface VrUiHotspot extends UiHotspot {
    active: boolean;
}

/**
 * Component to render the top view with vr-cameras placed as hotspots on it.
 */
@Component({
    selector: 'mhp-vr-cameras-view',
    templateUrl: './vr-cameras-view.component.html',
    styleUrls: ['./vr-cameras-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VrCamerasViewComponent {
    protected readonly EnvironmentLightingProfileState =
        EnvironmentLightingProfileState;

    constructor(
        private readonly staticRendererService: StaticRendererService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly engineStateService: EngineStateService,
        private readonly cameraControlService: CameraControlService,
        private readonly productDataService: ProductDataService<AMLCameraMeta>,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        protected readonly labelHelperService: LabelHelperService
    ) {}

    /**
     * Select the given camera.
     * @param cameraId The ID of the camera to select.
     */
    intentSelectCamera(cameraId: string): void {
        this.cameraControlService.setActiveCamera$(cameraId).subscribe();
    }

    /**
     * Callback for a hotspot event.
     * @param event The event which happened on a hotspot.
     */
    onHotspotEvent(event: UiHotspotEvent<VrUiHotspot>) {
        if (event.type === UiHotspotEventType.SELECT) {
            this.intentSelectCamera(event.hotspot.id);
        }
    }

    /**
     * Get the image-definition for the top-view rendering.
     */
    @MemoizeObservable()
    getTopViewRendering$(): Observable<ImageSrcset | undefined> {
        const defaultedTopViewSessionData$ = combineLatest([
            this.productConfigurationSessionService.getOptionGroups$(),
            this.productConfigurationSessionService
                .getActiveConfiguration$()
                .pipe(
                    map((activeConfiguration) => ({
                        productId: activeConfiguration?.productId,
                        country: activeConfiguration?.country
                    }))
                )
        ]).pipe(
            map(([optionGroups, productInfo]) => {
                if (!optionGroups || !productInfo) {
                    return undefined;
                }

                const exteriorGroupName =
                    environment.appConfig.configuration.identifierExterior;
                const paintGroupName =
                    environment.appConfig.configuration.identifierPaint;

                // get the currently selected node inside the exterior / paint hierarchy
                const nodes =
                    this.nodeLookupService.collectNodes<ExtendedUiOptionCode>(
                        (node, parents) =>
                            node.hierarchyPath[0]?.name === exteriorGroupName &&
                            node.hierarchyPath[1]?.name === paintGroupName &&
                            isExtendedUiOptionCode(node) &&
                            node.selected,
                        optionGroups
                    );

                return {
                    productId: productInfo.productId,
                    country: productInfo.country,
                    externalColorOptions: nodes.map((node) => node.code)
                };
            }),
            distinctUntilChangedEquality(),
            switchMap((patchInput) => {
                if (
                    !patchInput ||
                    !patchInput.productId ||
                    !patchInput.country
                ) {
                    return of(undefined);
                }
                const { productId, country } = patchInput;
                return this.productConfigurationService
                    .patchConfiguration$(
                        productId,
                        country,
                        [],
                        patchInput.externalColorOptions
                    )
                    .pipe(
                        map((patchedConfigModel) => ({
                            productId,
                            country,
                            config: patchedConfigModel
                        }))
                    );
            }),
            map((patchedDefaultConfig): EngineSessionData | undefined => {
                if (!patchedDefaultConfig) {
                    return undefined;
                }
                return {
                    // use studio-environment, always day, default-config with only exterior-color applied, top-camera, no animations
                    camera: {
                        id: 'BS_Config_Ext_Top',
                        options: {}
                    },
                    environment: {
                        id: 'Studio',
                        options: {}
                    },
                    animations: [],
                    country: patchedDefaultConfig.country,
                    config: {
                        id: patchedDefaultConfig.productId,
                        options: {
                            config: patchedDefaultConfig.config
                        }
                    }
                };
            })
        );

        return this.staticRendererService.getRenderingSrcset$(
            defaultedTopViewSessionData$,
            {
                transparentBackground: true
            }
        );
    }

    /**
     * Emit the camera-hotspots to be placed on the top-view stage.
     */
    @MemoizeObservable()
    getCameraHotspots$(): Observable<VrUiHotspot[] | undefined> {
        return combineLatest([
            this.productDataService.getAvailableCameras$(),
            this.engineStateService.getActiveCamera$()
        ]).pipe(
            map(
                ([availableCameras, activeCameraId]):
                    | VrUiHotspot[]
                    | undefined =>
                    availableCameras?.cameras
                        .map((camera): VrUiHotspot | undefined => {
                            if (!camera.meta?.topViewPositioning) {
                                return undefined;
                            }
                            return {
                                id: camera.id,
                                x: camera.meta?.topViewPositioning?.x,
                                y: camera.meta?.topViewPositioning?.y,
                                active: camera.id === activeCameraId
                            };
                        })
                        .filter((hotspot): hotspot is VrUiHotspot => !!hotspot)
            )
        );
    }
}
