"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PDFType = exports.LanguageType = exports.ImageType = exports.FileType = void 0;
var file_type_enum_1 = require("./file-type.enum");
Object.defineProperty(exports, "FileType", {
  enumerable: true,
  get: function () {
    return file_type_enum_1.FileType;
  }
});
var image_type_enum_1 = require("./image-type.enum");
Object.defineProperty(exports, "ImageType", {
  enumerable: true,
  get: function () {
    return image_type_enum_1.ImageType;
  }
});
var language_type_enum_1 = require("./language-type.enum");
Object.defineProperty(exports, "LanguageType", {
  enumerable: true,
  get: function () {
    return language_type_enum_1.LanguageType;
  }
});
var pdf_type_enum_1 = require("./pdf-type.enum");
Object.defineProperty(exports, "PDFType", {
  enumerable: true,
  get: function () {
    return pdf_type_enum_1.PDFType;
  }
});
