"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToClientMessageType = exports.MessageType = exports.WebSocketMessageType = exports.NoFrameLimitData = exports.LatencyLimitData = exports.BitrateLimitData = exports.ConnectionCutType = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var connection_cut_enum_1 = require("./connection-cut.enum");
Object.defineProperty(exports, "ConnectionCutType", {
  enumerable: true,
  get: function () {
    return connection_cut_enum_1.ConnectionCutType;
  }
});
var stream_monitoring_interface_1 = require("./stream-monitoring.interface");
Object.defineProperty(exports, "BitrateLimitData", {
  enumerable: true,
  get: function () {
    return stream_monitoring_interface_1.BitrateLimitData;
  }
});
Object.defineProperty(exports, "LatencyLimitData", {
  enumerable: true,
  get: function () {
    return stream_monitoring_interface_1.LatencyLimitData;
  }
});
Object.defineProperty(exports, "NoFrameLimitData", {
  enumerable: true,
  get: function () {
    return stream_monitoring_interface_1.NoFrameLimitData;
  }
});
var websocket_message_type_enum_1 = require("./websocket-message-type.enum");
Object.defineProperty(exports, "WebSocketMessageType", {
  enumerable: true,
  get: function () {
    return websocket_message_type_enum_1.WebSocketMessageType;
  }
});
var message_type_enum_1 = require("./message-type.enum");
Object.defineProperty(exports, "MessageType", {
  enumerable: true,
  get: function () {
    return message_type_enum_1.MessageType;
  }
});
Object.defineProperty(exports, "ToClientMessageType", {
  enumerable: true,
  get: function () {
    return message_type_enum_1.ToClientMessageType;
  }
});
