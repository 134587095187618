"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PARENT_MATERIALS_FACTORY = exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_SCALE = exports.MATERIAL_ATTRIBUTE_EXPOSE_SCUFF_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_ROUGHNESS = exports.MATERIAL_ATTRIBUTE_EXPOSE_LEATHER_SCALE = exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_MAP = exports.MATERIAL_ATTRIBUTE_EXPOSE_CC_ROUGHNESS = exports.MATERIAL_ATTRIBUTE_EXPOSE_ORANGE_PEEL_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_PAINT_OPACITY = exports.MATERIAL_ATTRIBUTE_EXPOSE_METALLIC = exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_AMOUNT = exports.MATERIAL_ATTRIBUTE_EXPOSE_TINT = void 0;
exports.MATERIAL_ATTRIBUTE_EXPOSE_TINT = 'Expose_Tint';
exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_AMOUNT = 'Expose_FlakeAmount';
exports.MATERIAL_ATTRIBUTE_EXPOSE_METALLIC = 'Expose_Metallic';
exports.MATERIAL_ATTRIBUTE_EXPOSE_PAINT_OPACITY = 'Expose_PaintOpacity';
exports.MATERIAL_ATTRIBUTE_EXPOSE_ORANGE_PEEL_AMOUNT = 'Expose_OrangePeelAmount';
exports.MATERIAL_ATTRIBUTE_EXPOSE_CC_ROUGHNESS = 'Expose_CCRoughness';
exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_MAP = 'Expose_NormalMap';
exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_AMOUNT = 'Expose_NormalAmount';
exports.MATERIAL_ATTRIBUTE_EXPOSE_LEATHER_SCALE = 'Expose_LeatherScale';
exports.MATERIAL_ATTRIBUTE_EXPOSE_ROUGHNESS = 'Expose_Roughness';
exports.MATERIAL_ATTRIBUTE_EXPOSE_SCUFF_AMOUNT = 'Expose_ScuffAmount';
exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_SCALE = 'Expose_FlakeScale';
class AbstractMaterialAttribute {
  constructor(id, type, value) {
    this.id = id;
    this.type = type;
    this.value = value;
  }
}
class AbstractFloatMaterialAttribute extends AbstractMaterialAttribute {
  constructor(id, value) {
    super(id, 'FLOAT', [value]);
  }
}
class AbstractColorMaterialAttribute extends AbstractMaterialAttribute {
  constructor(id, color) {
    super(id, 'COLOR', color);
  }
}
class AbstractTextureMaterialAttribute extends AbstractMaterialAttribute {
  constructor(id, textureId) {
    super(id, 'TEXTURE', textureId);
  }
}
class ExposeFlakeAmount extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_AMOUNT, value);
  }
}
class ExposeMetallic extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_METALLIC, value);
  }
}
class ExposePaintOpacity extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_PAINT_OPACITY, value);
  }
}
class ExposeTint extends AbstractColorMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_TINT, value);
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ExposeOrangePeelAmount extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_ORANGE_PEEL_AMOUNT, value);
  }
}
class ExposeCCRoughness extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_CC_ROUGHNESS, value);
  }
}
class ExposeRoughness extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_ROUGHNESS, value);
  }
}
class ExposeLeatherScale extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_LEATHER_SCALE, value);
  }
}
class ExposeNormalAmount extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_AMOUNT, value);
  }
}
class ExposeNormalMap extends AbstractTextureMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_NORMAL_MAP, value);
  }
}
class ExposeScuffAmount extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_SCUFF_AMOUNT, value);
  }
}
class ExposeFlakeScale extends AbstractFloatMaterialAttribute {
  constructor(value) {
    super(exports.MATERIAL_ATTRIBUTE_EXPOSE_FLAKE_SCALE, value);
  }
}
/**
 * Factory function to obtain a static list of defined parent-materials.
 */
const PARENT_MATERIALS_FACTORY = () =>
// TODO: Add undocumented MP_CarbonFiber_HG and MP_CarbonFiber_Matt
[{
  id: 'MP_CarPaint_Metallic',
  attributes: [new ExposeFlakeAmount(0.25), new ExposeMetallic(0.5), new ExposeTint([0.33, 0.33, 0.33]), new ExposeFlakeScale(1)]
}, {
  id: 'MP_CarPaint_Uni',
  attributes: [new ExposeTint([0.0, 0.0, 0.0])]
}, {
  id: 'MP_CarPaint_Matt',
  attributes: [new ExposeFlakeAmount(0.25), new ExposeMetallic(0.7), new ExposeTint([0.33, 0.33, 0.33]), new ExposeCCRoughness(0.25), new ExposeFlakeScale(1)]
}, {
  id: 'MP_CarPaint_Matt_Uni',
  attributes: [new ExposeTint([0.33, 0.33, 0.33]), new ExposeCCRoughness(0.35)]
}, {
  id: 'MP_Tinted_Carbon_Gloss',
  attributes: [new ExposeTint([0.02, 0.02, 0.02])]
}, {
  id: 'MP_Tinted_Met_Carbon_Gloss',
  attributes: [new ExposeTint([0.02, 0.02, 0.02]), new ExposePaintOpacity(0.3), new ExposeFlakeAmount(0.25), new ExposeFlakeScale(1)]
}, {
  id: 'MP_Tinted_Carbon_Matt',
  attributes: [new ExposeTint([0.02, 0.02, 0.02]), new ExposeCCRoughness(0.4)]
}, {
  id: 'MP_Tinted_Met_Carbon_Matt',
  attributes: [new ExposeTint([0.02, 0.02, 0.02]), new ExposePaintOpacity(0.3), new ExposeFlakeAmount(0.25), new ExposeCCRoughness(0.4), new ExposeFlakeScale(1)]
}, {
  id: 'MP_Leather_Premium',
  attributes: [new ExposeTint([0.11, 0.03, 0.015]), new ExposeNormalMap(null), new ExposeNormalAmount(0.3), new ExposeLeatherScale(4), new ExposeRoughness(0.65)]
}, {
  id: 'MP_Leather_Signature',
  attributes: [new ExposeTint([0.11, 0.03, 0.015]), new ExposeNormalMap(null), new ExposeNormalAmount(0.3), new ExposeLeatherScale(4), new ExposeRoughness(0.65), new ExposeScuffAmount(0)]
}, {
  id: 'MP_Alcantara_Setup',
  attributes: [new ExposeTint([0.12, 0.12, 0.12])]
}, {
  id: 'MP_Stitching',
  attributes: [new ExposeTint([0.3, 0.3, 0.3])]
}];
exports.PARENT_MATERIALS_FACTORY = PARENT_MATERIALS_FACTORY;
