import {
    RECAPTCHA_BASE_URL,
    RECAPTCHA_V3_SITE_KEY,
    RecaptchaV3Module
} from 'ng-recaptcha';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import {
    MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatTooltipDefaultOptions
} from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifiableError } from '@bugsnag/core/types/common';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular/dist/esm2015';
import {
    getLocaleByCountryISOCode,
    getMapOfLocaleToCurrencyISOCodes
} from '@mhp/aml-shared/country-mapping/country-mapping';
import {
    CookieModule,
    DEALER_INFO_CONFIG_TOKEN,
    DealerInfoConfig,
    DealerInfoService,
    ONE_2_ONE_SESSION_URL_SERVICE_CONFIG_TOKEN,
    ONE_TRUST_SERVICE_CONFIG_TOKEN,
    One2OneSessionUrlServiceConfig,
    OneTrustService,
    OneTrustServiceConfig,
    SALESFORCE_SERVICE_CONFIG_TOKEN,
    SESSION_URL_SERVICE_CONFIG_TOKEN,
    SalesforceServiceConfig,
    SessionUrlServiceConfig
} from '@mhp/aml-ui-shared-services';
import { SalesforceTrackingModule } from '@mhp/aml-ui-shared-services/salesforce-tracking/salesforce-tracking.module';
import {
    UI_LAZY_LOAD_IMAGE_COMPONENT_CONFIG_TOKEN,
    UI_MAT_DIALOG_SERVICE_CONFIG,
    UiDatepickerModule,
    UiLazyloadImageComponentConfig,
    UiLazyloadImageModule,
    UiMatDialogModule,
    UiMatDialogServiceConfig,
    UiNotificationModule,
    UiSelectModule,
    UiTooltipModule,
    UiTouchPanelHammerModule
} from '@mhp/ui-components';
import {
    ApplicationStateModule,
    COMMON_DIALOGS_SERVICE_CONFIG_TOKEN,
    CommonDialogsServiceConfig,
    DialogButtonType,
    ERROR_HANDLER_CONFIG_TOKEN,
    ErrorHandlerModule,
    ErrorHandlerServiceConfig,
    GoogleTagManagerModule,
    I18N_SETTINGS_MANAGER_TOKEN,
    I18nModule,
    L10N_SERVICE_CONFIG_TOKEN,
    L10nModule,
    L10nServiceConfig,
    MONKEYWAY_ENVIRONMENT_CONFIG_TOKEN,
    MonkeywayEnvironmentConfig,
    PRODUCT_CONFIGURATION_HTTP_STRATEGY_CONFIG_TOKEN,
    PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN,
    PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN,
    ProductConfigurationHttpStrategy,
    ProductConfigurationHttpStrategyConfig,
    ProductConfigurationModule,
    ProductDataModule,
    SERIALIZER_SERVICE_CONFIG_TOKEN,
    STATIC_ASSETS_SERVICE_CONFIG_TOKEN,
    SerializerServiceConfig,
    SlidingTimeWindowBasedStreamingEventHandlerFactory,
    StaticAssetsServiceConfig,
    URL_SHORTENER_SERVICE_CONFIG_TOKEN,
    UpdatableStrategyProvider,
    UrlShortenerServiceConfig
} from '@mhp/ui-shared-services';

import { environment } from '../environments/environment';
import { SCOPE_DEPENDENT_MODULES } from '../environments/environment.modules';
import { VERSION } from '../version';
import { ROUTE_CONFIGURATION } from './app-route-names';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BACKDROP_CLASS_BLURRY } from './common/dialog/dialog.constants';
import { LoadingIndicatorModule } from './common/loading-indicator/loading-indicator.module';
import { CpqSessionHandlerService } from './configuration/cpq/cpq-session-handler.service';
import { UtmParametersService } from './configuration/salesforce/utm-parameters/utm-parameters.service';
import {
    DIALOG_HEADER_CONFIG_TOKEN,
    DialogHeaderCloseIconPosition,
    DialogHeaderConfig
} from './dialog/dialog-header/dialog-header.component';
import { DialogModule } from './dialog/dialog.module';
import { GenericTextDialogComponent } from './dialog/generic-text-dialog/generic-text-dialog.component';
import { ErrorModule } from './error/error.module';
import { AML_I18N_CONFIG } from './i18n/aml-i18n-config';
import { I18nLocalStateSettingsManager } from './i18n/i18n-local-state-settings-manager';
import { StreamInactivityService } from './inactivity/stream/stream-inactivity.service';
import { UserInactivityModule } from './inactivity/user-inactivity/user-inactivity.module';
import { LatinNumberTransformer } from './l10n/pricing/latin-number-transformer';
import { MenuModule } from './menu/menu.module';
import {
    AmlProductDataStrategyImpl,
    PRODUCT_DATA_HTTP_STRATEGY_CONFIG_TOKEN,
    ProductDataHttpStrategyConfig
} from './product-data/aml-product-data-strategy-impl';
import { SettingsModule } from './settings/settings.module';
import { localApplicationStateReducer } from './state/reducers/local-application-state.reducer';

const IMAGE_PLACEHOLDER_URL = 'assets/images/placeholder.svg';

Bugsnag.start({
    apiKey: environment.appConfig.bugsnag.apiKey,
    releaseStage: environment.stage,
    enabledReleaseStages: ['production'],
    appVersion: VERSION.raw,
    metadata: {
        'Execution Context': {
            publicConfigurator:
                !environment.appConfig.dealer.dealerBuild &&
                !environment.appConfig.brandStore.brandStoreBuild,
            dealerConfigurator:
                environment.appConfig.dealer.dealerBuild &&
                !environment.appConfig.brandStore.brandStoreBuild,
            brandStoreConfigurator:
                environment.appConfig.brandStore.brandStoreBuild
        }
    }
});

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

function oneTrustInitializerFactory(oneTrustService: OneTrustService) {
    return () => oneTrustService.initOneTrustCookieConsentScript();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        // PLATFORM MODULES
        ApplicationStateModule.forRoot({
            local: localApplicationStateReducer
        }),
        ErrorHandlerModule,
        I18nModule.forRoot(AML_I18N_CONFIG()),
        ProductDataModule.forRoot({
            productDataStrategyProviderDefinition:
                // Define communication facade for ProductDataService
                {
                    provide: PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN,
                    useFactory: (productDataStrategy) =>
                        new UpdatableStrategyProvider({
                            strategy: productDataStrategy,
                            meta: {
                                type: 'HTTP'
                            }
                        }),
                    deps: [AmlProductDataStrategyImpl]
                }
        }),
        ProductConfigurationModule.forRoot({
            productConfigurationStrategyProviderDefinition: {
                // Define communication facade for ProductConfigurationService
                provide: PRODUCT_CONFIGURATION_STRATEGY_PROVIDER_TOKEN,
                useFactory: (productConfigurationStrategy) =>
                    new UpdatableStrategyProvider({
                        strategy: productConfigurationStrategy,
                        meta: {
                            type: 'HTTP'
                        }
                    }),
                deps: [ProductConfigurationHttpStrategy]
            }
        }),
        L10nModule.forRoot(
            {
                localeToCurrencyMapping: getMapOfLocaleToCurrencyISOCodes()
            },
            LatinNumberTransformer
        ),
        RecaptchaV3Module,
        GoogleTagManagerModule.forRoot({
            trackingId: environment.appConfig.googleTagManager.trackingId,
            gtmEnvironmentSpec:
                environment.appConfig.googleTagManager.environmentSpec ??
                undefined,
            sequenceProperties: new Set<string>([
                'model_category',
                'model_id',
                'model_name',
                'page_menu',
                'page_submenu',
                'variant_category',
                'variant_option',
                'active_environment',
                'vehicle_angle'
            ])
        }),
        SalesforceTrackingModule.forRoot({
            beaconScriptUrl: environment.appConfig.salesforceDataCloud.beaconUrl
        }),

        UiMatDialogModule,
        UiTooltipModule,
        UiSelectModule,
        UiLazyloadImageModule,
        UiNotificationModule.forRoot(),
        UiTouchPanelHammerModule.forRoot(),
        UiDatepickerModule,
        MatNativeDateModule,

        // LOCAL MODULES
        SettingsModule,
        ErrorModule,
        MenuModule,
        DialogModule,
        CookieModule,
        LoadingIndicatorModule,
        UserInactivityModule,

        ...SCOPE_DEPENDENT_MODULES
    ],
    providers: [
        // APP INITIALIZER
        {
            provide: APP_INITIALIZER,
            useFactory: oneTrustInitializerFactory,
            deps: [OneTrustService],
            multi: true
        },

        // ERROR HANDLER
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        // STRATEGIES
        AmlProductDataStrategyImpl,
        ProductConfigurationHttpStrategy,

        // CONFIG-TOKENS
        {
            provide: I18N_SETTINGS_MANAGER_TOKEN,
            useClass: I18nLocalStateSettingsManager
        },
        {
            provide: L10N_SERVICE_CONFIG_TOKEN,
            useValue: <L10nServiceConfig>{
                storageKeyCountry: 'AML_COUNTRY',
                countryToLocaleMapper: (country) =>
                    getLocaleByCountryISOCode(country)
            }
        },
        {
            provide: ERROR_HANDLER_CONFIG_TOKEN,
            useValue: <ErrorHandlerServiceConfig>{
                maxRetries: 3,
                retryScalingDuration: 1000,
                maxRetryTimeout: 10000,
                appendRootErrorMessage:
                    environment.appConfig.errors
                        .appendRootErrorMessageInUiNotification,
                errorCallback: (error: NotifiableError) => {
                    Bugsnag.notify(error, (event) => {
                        event.severity = 'error';
                    });
                }
            }
        },
        {
            provide: PRODUCT_DATA_HTTP_STRATEGY_CONFIG_TOKEN,
            useFactory: (
                dealerInfoService: DealerInfoService
            ): ProductDataHttpStrategyConfig => {
                // build a fallbackUuid to be used in case no dealer-id is available during runtime.
                const fallbackUuid = uuidv4();
                return {
                    baseUrl: environment.appConfig.productData.url,
                    // append an additional searchParameter to distinguish between public and dealer requests. See https://mhpimmersive.atlassian.net/browse/AMIF-497
                    searchParamsModifier: !environment.appConfig.dealer
                        .dealerBuild
                        ? undefined
                        : (endpoint, searchParams) =>
                              firstValueFrom(
                                  dealerInfoService.getActiveDealerInfo$().pipe(
                                      catchError(() => of(undefined)),
                                      map((dealerInfo) => {
                                          searchParams.accNo =
                                              dealerInfo?.accountNumber?.toString() ??
                                              fallbackUuid;
                                          return searchParams;
                                      })
                                  )
                              )
                };
            },
            deps: [DealerInfoService]
        },
        {
            provide: PRODUCT_CONFIGURATION_HTTP_STRATEGY_CONFIG_TOKEN,
            useFactory: (
                dealerInfoService: DealerInfoService,
                cpqSessionHandlerService: CpqSessionHandlerService
            ): ProductConfigurationHttpStrategyConfig => {
                // build a fallbackUuid to be used in case no dealer-id is available during runtime.
                const fallbackUuid = uuidv4();
                return {
                    baseUrl: environment.appConfig.ruler.url,

                    httpInterceptor: cpqSessionHandlerService,

                    // append an additional searchParameter to distinguish between public and dealer requests. See https://mhpimmersive.atlassian.net/browse/AMIF-497
                    searchParamsModifier: !environment.appConfig.dealer
                        .dealerBuild
                        ? undefined
                        : (endpoint, searchParams) =>
                              firstValueFrom(
                                  dealerInfoService.getActiveDealerInfo$().pipe(
                                      catchError(() => of(undefined)),
                                      map((dealerInfo) => {
                                          searchParams.accNo =
                                              dealerInfo?.accountNumber?.toString() ??
                                              fallbackUuid;
                                          return searchParams;
                                      })
                                  )
                              )
                };
            },
            deps: [DealerInfoService, CpqSessionHandlerService]
        },
        {
            provide: UI_LAZY_LOAD_IMAGE_COMPONENT_CONFIG_TOKEN,
            useValue: <UiLazyloadImageComponentConfig>{
                placeholderImageUrl: IMAGE_PLACEHOLDER_URL
            }
        },
        {
            provide: STATIC_ASSETS_SERVICE_CONFIG_TOKEN,
            useValue: <StaticAssetsServiceConfig>{
                httpServerUrl: environment.appConfig.assets.url,
                placeholderImageUrl: IMAGE_PLACEHOLDER_URL,
                cameraThumbnailSizes:
                    environment.appConfig.assets.cameraSrcsetSizes, // keep for brand-store
                environmentThumbnailSizes:
                    environment.appConfig.assets.environmentSrcsetSizes, // keep for brand-store
                animationThumbnailSizes:
                    environment.appConfig.assets.animationSrcsetSizes, // keep for brand-store
                splitterThumbnailSizes:
                    environment.appConfig.assets.splitterSrcsetSizes,
                optionThumbnailSizes:
                    environment.appConfig.assets.optionSrcsetSizes,
                interiorEnvironmentImageSizes:
                    environment.appConfig.assets.interiorSrcsetSizes,
                optionMetaThumbnailPrefixKey:
                    environment.appConfig.configuration.optionMetadata
                        .thumbnailPrefix
            }
        },
        {
            provide: MONKEYWAY_ENVIRONMENT_CONFIG_TOKEN,
            useValue: <MonkeywayEnvironmentConfig>{
                baseUrl: environment.appConfig.stream.url,
                appEnvId: environment.appConfig.stream.appEnvId,
                apiKey: environment.appConfig.stream.apiKey,
                cutStreamLimits: {
                    latency:
                        environment.appConfig.stream.cutStreamLimits.latency,
                    bitrate:
                        environment.appConfig.stream.cutStreamLimits.bitrate,
                    noFramesRatio:
                        environment.appConfig.stream.cutStreamLimits
                            .noFramesRatio,
                    handlerFactory:
                        new SlidingTimeWindowBasedStreamingEventHandlerFactory({
                            checkPeriodTimespan:
                                environment.appConfig.stream
                                    .checkForCutStreamLimitsInterval,
                            windowTimespan:
                                environment.appConfig.stream
                                    .buildCutStreamLimitMeanValuesOverTime,
                            connectionReportCheckInterval:
                                environment.appConfig.stream
                                    .internalConnectionStatsCheckInterval,
                            cutStreamLimits: {
                                latency:
                                    environment.appConfig.stream.cutStreamLimits
                                        .latency,
                                bitrate:
                                    environment.appConfig.stream.cutStreamLimits
                                        .bitrate,
                                noFramesRatio:
                                    environment.appConfig.stream.cutStreamLimits
                                        .noFramesRatio
                            }
                        })
                }
            }
        },
        {
            provide: UI_MAT_DIALOG_SERVICE_CONFIG,
            useValue: <UiMatDialogServiceConfig>{
                dialogMaxWidth: '100vw - 40px'
            }
        },
        {
            provide: DIALOG_HEADER_CONFIG_TOKEN,
            useValue: <DialogHeaderConfig>{
                defaultCloseIconPosition: DialogHeaderCloseIconPosition.END
            }
        },
        {
            provide: COMMON_DIALOGS_SERVICE_CONFIG_TOKEN,
            useValue: <CommonDialogsServiceConfig>{
                defaultDialogComponent: GenericTextDialogComponent,
                defaultCancelButtonType: DialogButtonType.SECONDARY,
                defaultDialogOptions: {
                    backdropClass: BACKDROP_CLASS_BLURRY,
                    panelClass: ['mhp-ui-modal-panel--no-overflow']
                }
            }
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.appConfig.recaptcha.siteKey
        },
        {
            provide: RECAPTCHA_BASE_URL,
            useValue: 'https://recaptcha.net/recaptcha/api.js'
        },
        {
            provide: URL_SHORTENER_SERVICE_CONFIG_TOKEN,
            useValue: <UrlShortenerServiceConfig>{
                baseUrl: environment.appConfig.urlShortener.url
            }
        },
        {
            provide: DEALER_INFO_CONFIG_TOKEN,
            useValue: <DealerInfoConfig>{
                dataProxyBaseUrl: environment.appConfig.dataProxy.url,
                isDealerBuild: environment.appConfig.dealer.dealerBuild
            }
        },
        {
            provide: SALESFORCE_SERVICE_CONFIG_TOKEN,
            useValue: <SalesforceServiceConfig>{
                dataProxyBaseUrl: environment.appConfig.dataProxy.url
            }
        },
        {
            provide: SESSION_URL_SERVICE_CONFIG_TOKEN,
            useValue: <SessionUrlServiceConfig>{
                dealerBaseUrl: environment.appConfig.baseUrls.dealerBaseUrl,
                configurationRouteSegmentName: ROUTE_CONFIGURATION
            }
        },
        {
            provide: ONE_2_ONE_SESSION_URL_SERVICE_CONFIG_TOKEN,
            useValue: <One2OneSessionUrlServiceConfig>{
                publicBaseUrl: environment.appConfig.baseUrls.publicBaseUrl,
                sessionHostReservationKeyQueryParamName:
                    environment.appConfig.one2one
                        .dealerReservationKeyQueryParamName
            }
        },
        {
            provide: SERIALIZER_SERVICE_CONFIG_TOKEN,
            useValue: <SerializerServiceConfig>{
                registerDebugFunctions: !environment.production
            }
        },
        {
            provide: ONE_TRUST_SERVICE_CONFIG_TOKEN,
            useValue: <OneTrustServiceConfig>{
                loadAutoBlockScript:
                    environment.appConfig.oneTrust.loadAutoBlockScript,
                domainScriptId: environment.appConfig.oneTrust.domainScriptId
            }
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: <MatTooltipDefaultOptions>{
                position: 'above'
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        // eager init
        private readonly streamInactivityService: StreamInactivityService,
        private readonly utmParametersService: UtmParametersService
    ) {}
}
