"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserResponseInterface = exports.UserRoleEnum = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var enums_1 = require("./enums");
Object.defineProperty(exports, "UserRoleEnum", {
  enumerable: true,
  get: function () {
    return enums_1.UserRoleEnum;
  }
});
var interfaces_1 = require("./interfaces");
Object.defineProperty(exports, "UserResponseInterface", {
  enumerable: true,
  get: function () {
    return interfaces_1.UserResponseInterface;
  }
});
