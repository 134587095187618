"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MatchmakerStreamerDisconnectMessage = exports.MatchmakerStreamerConnectMessage = exports.MatchmakerPlayerDisconnectMessage = exports.MatchmakerPlayerConnectMessage = exports.MatchmakerPingMessage = exports.MatchmakerMessageType = exports.MatchmakerDisconnectMessage = exports.MatchmakerConnectMessage = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var matchmaker_socket_messages_1 = require("./matchmaker-socket-messages");
Object.defineProperty(exports, "MatchmakerConnectMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerConnectMessage;
  }
});
Object.defineProperty(exports, "MatchmakerDisconnectMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerDisconnectMessage;
  }
});
Object.defineProperty(exports, "MatchmakerMessageType", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerMessageType;
  }
});
Object.defineProperty(exports, "MatchmakerPingMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerPingMessage;
  }
});
Object.defineProperty(exports, "MatchmakerPlayerConnectMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerPlayerConnectMessage;
  }
});
Object.defineProperty(exports, "MatchmakerPlayerDisconnectMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerPlayerDisconnectMessage;
  }
});
Object.defineProperty(exports, "MatchmakerStreamerConnectMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerStreamerConnectMessage;
  }
});
Object.defineProperty(exports, "MatchmakerStreamerDisconnectMessage", {
  enumerable: true,
  get: function () {
    return matchmaker_socket_messages_1.MatchmakerStreamerDisconnectMessage;
  }
});
