"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StreamingWebsocketMessage = exports.StreamingWebsocketCode = exports.InternalWebsocketCloseCodes = exports.FrontendWebsocketCloseCodes = exports.CollectedWebsocketCloseCodes = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var collected_websocket_close_codes_enum_1 = require("./collected-websocket-close-codes.enum");
Object.defineProperty(exports, "CollectedWebsocketCloseCodes", {
  enumerable: true,
  get: function () {
    return collected_websocket_close_codes_enum_1.CollectedWebsocketCloseCodes;
  }
});
var frontend_websocket_close_codes_enum_1 = require("./frontend-websocket-close-codes.enum");
Object.defineProperty(exports, "FrontendWebsocketCloseCodes", {
  enumerable: true,
  get: function () {
    return frontend_websocket_close_codes_enum_1.FrontendWebsocketCloseCodes;
  }
});
var internal_websocket_close_codes_enum_1 = require("./internal-websocket-close-codes.enum");
Object.defineProperty(exports, "InternalWebsocketCloseCodes", {
  enumerable: true,
  get: function () {
    return internal_websocket_close_codes_enum_1.InternalWebsocketCloseCodes;
  }
});
var streaming_websocket_code_message_class_1 = require("./streaming-websocket-code-message.class");
Object.defineProperty(exports, "StreamingWebsocketCode", {
  enumerable: true,
  get: function () {
    return streaming_websocket_code_message_class_1.StreamingWebsocketCode;
  }
});
var streaming_websocket_message_class_1 = require("./streaming-websocket-message.class");
Object.defineProperty(exports, "StreamingWebsocketMessage", {
  enumerable: true,
  get: function () {
    return streaming_websocket_message_class_1.StreamingWebsocketMessage;
  }
});
