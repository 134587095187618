"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_CONFIGURATION_UPDATE = exports.instanceOfConfigurationPayload = exports.REQUEST_GET_CONFIGURATION_DETAILS = void 0;
var configuration_details_request_interface_1 = require("./configuration-details-request.interface");
Object.defineProperty(exports, "REQUEST_GET_CONFIGURATION_DETAILS", {
  enumerable: true,
  get: function () {
    return configuration_details_request_interface_1.REQUEST_GET_CONFIGURATION_DETAILS;
  }
});
var configuration_payload_interface_1 = require("./configuration-payload.interface");
Object.defineProperty(exports, "instanceOfConfigurationPayload", {
  enumerable: true,
  get: function () {
    return configuration_payload_interface_1.instanceOfConfigurationPayload;
  }
});
var configuration_update_event_interface_1 = require("./configuration-update-event.interface");
Object.defineProperty(exports, "EVENT_CONFIGURATION_UPDATE", {
  enumerable: true,
  get: function () {
    return configuration_update_event_interface_1.EVENT_CONFIGURATION_UPDATE;
  }
});
