"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUEST_SET_ENVIRONMENT = exports.REQUEST_SET_DAYTIME = exports.EnvironmentLightingProfileState = exports.ContextOptionType = void 0;
/**
 * DONT EDIT THIS FILE MANUALLY
 * This file is autogenerated by `yarn run generate:index`
 */
var environment_interface_1 = require("./environment.interface");
Object.defineProperty(exports, "ContextOptionType", {
  enumerable: true,
  get: function () {
    return environment_interface_1.ContextOptionType;
  }
});
Object.defineProperty(exports, "EnvironmentLightingProfileState", {
  enumerable: true,
  get: function () {
    return environment_interface_1.EnvironmentLightingProfileState;
  }
});
var set_daytime_request_interface_1 = require("./set-daytime-request.interface");
Object.defineProperty(exports, "REQUEST_SET_DAYTIME", {
  enumerable: true,
  get: function () {
    return set_daytime_request_interface_1.REQUEST_SET_DAYTIME;
  }
});
var switch_environments_request_interface_1 = require("./switch-environments-request.interface");
Object.defineProperty(exports, "REQUEST_SET_ENVIRONMENT", {
  enumerable: true,
  get: function () {
    return switch_environments_request_interface_1.REQUEST_SET_ENVIRONMENT;
  }
});
