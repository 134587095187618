"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StreamingWebsocketMessage = void 0;
const class_transformer_1 = require("class-transformer");
class StreamingWebsocketMessage {
  constructor(type) {
    this.type = type;
  }
  serialize() {
    return JSON.stringify(this);
  }
  static deserialize(ctor, data) {
    return (0, class_transformer_1.plainToInstance)(ctor, data);
  }
}
exports.StreamingWebsocketMessage = StreamingWebsocketMessage;
