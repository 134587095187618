<mhp-ui-lazyload-image
    [imageUrl]="getTopViewRendering$() | async"
    [imageFit]="'contain'"
    [aspectRatio]="2048 / 1080"
>
</mhp-ui-lazyload-image>
<mhp-ui-hotspots
    [hotspots]="getCameraHotspots$() | async"
    [referenceDimensions]="{
        width: 2048,
        height: 1080
    }"
    [objectFit]="'contain'"
    [hotspotTemplateRef]="hotspotTemplate"
    [assignTabIndex]="false"
    (hotspotEvent)="onHotspotEvent($event)"
>
</mhp-ui-hotspots>
@if (labelHelperService.getActiveProductLogoInfo$() | async; as productLogoInfo)
{
<div class="vr-cameras-view__model-logo">
    <div
        class="vr-cameras-view__model-logo-image"
        [ngClass]="{
            'vr-cameras-view__model-logo-image--2L':
                productLogoInfo.logoType === '2L'
        }"
    >
        <img [src]="productLogoInfo.imageSrc" />
    </div>
</div>
}
<ng-template #hotspotTemplate let-uiHotspot="uiHotspot">
    <button
        *transloco="let t"
        class="vr-camera-hotspot"
        [ngClass]="{
            'vr-camera-hotspot--active': uiHotspot.active
        }"
        [attr.aria-label]="
            t('BRAND_STORE.VR.ALT_VR_CAMERA', { name: uiHotspot.id })
        "
        tabindex="0"
        mat-ripple
    >
        <div class="vr-camera-hotspot__inner"></div>
    </button>
</ng-template>
