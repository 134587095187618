"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebsocketErrorCode = void 0;
var websocket_error_codes_1 = require("./websocket-error-codes");
Object.defineProperty(exports, "WebsocketErrorCode", {
  enumerable: true,
  get: function () {
    return websocket_error_codes_1.WebsocketErrorCode;
  }
});
